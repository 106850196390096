export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Não há nada para ver aqui...',
  subheader: 'Página não encontrada.',
  buttonText: 'Voltar à página inicial',
}
